import React from "react"
import { GatsbyImage } from "gatsby-plugin-image"
import classNames from "classnames"
import { RichText } from "prismic-reactjs"

import Layout from "~/components/layout"
import SEO from "~/components/seo"
import Button from "~/components/ui/button"

import * as styles from "./index.module.scss"

interface Props {
  pageContext: any
}

const CompanyTemplate: React.FC<Props> = ({ pageContext: { data } }) => {
  return (
    <Layout>
      <SEO title={data.name.text} />
      <article className={styles.root}>
        <div className={styles.background}>
          <GatsbyImage
            alt={data.background_image.localFile.alt || ""}
            image={
              data.background_image.localFile.childImageSharp.gatsbyImageData
            }
            objectFit="cover"
            objectPosition="50% 50%"
          />
        </div>
        <div className={styles.container}>
          <div className={styles.main}>
            <div className={styles.logo}>
              <GatsbyImage
                alt={data.logo.localFile.alt || ""}
                image={data.logo.localFile.childImageSharp.gatsbyImageData}
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
            <div className={styles.textContainer}>
              <h1
                className={classNames("headline--decorated", styles.headline)}
              >
                {data.name.text}
              </h1>
              <div className={styles.img__mobile}>
                <GatsbyImage
                  alt={data.mobile_picture.localFile.alt || ""}
                  image={
                    data.mobile_picture.localFile.childImageSharp
                      .gatsbyImageData
                  }
                  objectFit="cover"
                  objectPosition="50% 50%"
                />
              </div>
              <RichText render={data.description.raw} />
              {data.mission_statement.raw.length > 0 && (
                <div
                  className={classNames("headline--decorated", styles.mission)}
                >
                  <h2 className={styles.mission__headline}>
                    <span>Mission</span> Statement
                  </h2>
                  <RichText render={data.mission_statement.raw} />
                </div>
              )}
              {data.link.url && (
                <div className={styles.link}>
                  <Button isLink={true} to={data.link.url}>
                    Visit Site
                  </Button>
                </div>
              )}
            </div>
            <div className={styles.img__desktop}>
              <GatsbyImage
                alt={data.desktop_picture.localFile.alt || ""}
                image={
                  data.desktop_picture.localFile.childImageSharp.gatsbyImageData
                }
                objectFit="cover"
                objectPosition="50% 50%"
              />
            </div>
          </div>
        </div>
      </article>
    </Layout>
  )
}

export default CompanyTemplate
