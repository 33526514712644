// extracted by mini-css-extract-plugin
export var background = "index-module--background--e211f";
export var container = "index-module--container--1aac3";
export var headline = "index-module--headline--99d87";
export var img__desktop = "index-module--img__desktop--8e9cb";
export var img__mobile = "index-module--img__mobile--f3fdc";
export var link = "index-module--link--97e03";
export var logo = "index-module--logo--34c99";
export var main = "index-module--main--38e75";
export var mission = "index-module--mission--73b97";
export var mission__headline = "index-module--mission__headline--b9f08";
export var root = "index-module--root--42826";
export var textContainer = "index-module--text-container--8811a";